import React, { useState, useEffect } from "react"
import { graphql, StaticQuery, navigate } from "gatsby"
import Transition from "../components/Transition"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Work from "../components/Work"
import NextButton from "../components/NextButton"
import WorkTemplate from "./blogTemplate"

class Template extends React.Component {
  constructor(props) {
    super(props)

    const {
      data, // this prop will be injected by the GraphQL query below.
      children,
      location,
      pageContext,
    } = props

    const { previous, next } = pageContext

    this.changeWorkTimeout = 800

    this.state = {
      post: data.markdownRemark,
      next,
      previous,
      dissolveAnimate: false,
      selectedPath: null,
    }
  }

  render() {
    return (
      <WorkTemplate
        frontmatter={this.state.post.frontmatter}
        html={this.state.post.html}
        {...this.props}
      />
    )
  }
}

export default Template

export const pageQuery = graphql`
  query ABC($path: String!) {
    config: markdownRemark {
      html
      fields {
        slug
      }
      frontmatter {
        title
        language
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        id
        title
        path
        services
        cover
        videoUrl
        image
        description
        areas
      }
    }
  }
`
