import React, { useState, useEffect } from "react"
import { graphql, StaticQuery, navigate } from "gatsby"
import Transition from "../components/Transition"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Work from "../components/Work"
import NextButton from "../components/NextButton"

export default ({
  frontmatter,
  html,
  children,
  location,
  pageContext,
  ...props
}) => {
  const { previous, next } = pageContext
  const [dissolveAnimate, setDissolveAnimate] = useState(false)
  const [selectedPath, setSelectedPath] = useState()
  const changeWorkTimeout = 800

  const changeWork = path => {
    setSelectedPath(path)
    setDissolveAnimate(true)
  }

  useEffect(() => {
    if (dissolveAnimate === false) return

    setTimeout(() => {
      setDissolveAnimate(false)

      navigate(selectedPath)
    }, changeWorkTimeout)
  }, [dissolveAnimate])

  const toTitleCase = phrase => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }

  return (
    <Layout>
      <SEO
        title={
          frontmatter.title
            ? toTitleCase(frontmatter.title)
            : "Manastır - A Design-Oriented Integrated Solution Agency"
        }
      />
      <div className="blog-post-container" style={{ marginTop: "90px" }}>
        <div className="blog-post">
          <Transition location={location}>
            {frontmatter.title && (
              <Work
                {...frontmatter}
                dissolveAnim={dissolveAnimate}
                animationDelay={changeWorkTimeout}
                html={html}
              />
            )}
            {next && (
              <NextButton
                {...next.frontmatter}
                onClick={() => changeWork(next.frontmatter.path)}
              />
            )}
          </Transition>
        </div>
      </div>
    </Layout>
  )
}
