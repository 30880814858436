import React, { Fragment, useEffect, useState } from "react"
import AnimateHeight from "react-animate-height"
import "./work.css"

const Work = ({
  title,
  date,
  description,
  services,
  areas,
  image,
  html,
  videoUrl,
  ...props
}) => {
  const { dissolveAnim, animationDelay } = props
  const [dissolveAnimate, setDissolveAnimate] = useState(true)

  useEffect(() => {
    setDissolveAnimate(false)
  }, [])

  return (
    <Fragment>
      <AnimateHeight
        duration={animationDelay}
        height={dissolveAnim || dissolveAnimate ? 0 : "auto"} // see props documentation below
      >
        <div className="row mb-20">
          <div className="col-6 col-sm mb-60">
            <div className="row">
              <div className="col-12">
                <h1 className="title">{title}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="area">
                  {areas &&
                    areas.map((area, i) => {
                      return (
                        <span key={i}>
                          {area}
                          {i !== areas.length - 1 ? ", " : "."}
                        </span>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="seperator-line hidden mb-60"></div>
          <div className="col-6 col-sm">
            <div className="row">
              <div className="col-12">
                <div className="description">{description}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="service">
                  {services &&
                    services.map((service, i) => {
                      return (
                        <span key={i}>
                          {service}
                          {i !== services.length - 1 ? ", " : "."}
                        </span>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Fragment>
              {videoUrl && (
                <video
                  style={{ width: "100%", marginBottom: `30px` }}
                  playsInline
                  autoPlay
                  muted
                  loop
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              )}
            </Fragment>
            {!videoUrl && image && (
              <img src={image} alt="" style={{ width: `100%` }} />
            )}
          </div>
        </div>
        <span dangerouslySetInnerHTML={{ __html: html }}></span>
      </AnimateHeight>
    </Fragment>
  )
}

export default Work
