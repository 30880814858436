import React, { Fragment } from "react"
import NextButtonStyles from "./nextButton.module.css"

const NextButton = ({ onClick, title, date, description, areas, services }) => (
  <Fragment>
    <div className={NextButtonStyles.separator}>
      <div className={NextButtonStyles.text} onClick={onClick}>
        NEXT PROJECT
      </div>
    </div>
    <div className="row mt-40 mb-40">
      <div className="col-6 col-sm">
        <div className="row ">
          <div className="col-12">
            <div className="title">{title}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="area">
              {areas &&
                areas.map((area, i) => {
                  return (
                    <span key={i}>
                      {area}
                      {i !== areas.length - 1 ? ", " : "."}
                    </span>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="seperator-line hidden mt-60 mb-60"></div>
      <div className="col-6 col-sm">
        <div className="row">
          <div className="col-12">
            <div className="description">{description}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="service">
              {services &&
                services.map((service, i) => {
                  return (
                    <span key={i}>
                      {service}
                      {i !== services.length - 1 ? ", " : "."}
                    </span>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default NextButton
